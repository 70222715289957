import { Link, NavLink } from 'react-router-dom'
import { Link as ScrollLink} from 'react-scroll';
import { useLocation } from 'react-router-dom';

import {ReactComponent as Logo} from '../../images/logo-unirmentes.svg'
import './navbar.css'
import { links } from '../../data'
import { IoMdMenu } from 'react-icons/io'
import { useState } from 'react'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { pathname } = useLocation();
  
  const scrollButton = (path, name) => {
    if (pathname === '/') {
      return <ScrollLink
        activeClass="nav-active"
        to={path}
        spy={true}
        smooth={true}
        offset={-70} // Adjust the offset if you have a fixed header
        duration={500} // Duration of the scroll animation in milliseconds
      >
        {name}
      </ScrollLink>
    }
    return <NavLink
      to={`/#${path}`}
      className={({ isActive }) => isActive ? 'nav-active' : ''}
      >
        {name}
      </NavLink>
  };

  const getButton = (link) => {
    if (link.type === 'scroll') {
      return scrollButton(link.path, link.name)
    }
    const isExternal = link.path.startsWith('http');
    let className = ({ isActive }) => {
      let className = isActive ? 'nav-active' : '';
      return className;
    };
    
    if (isExternal) {
      return <NavLink to={link.path} target='_blank' className={className}>{link.name}</NavLink>
    }
    return <NavLink to={link.path} className={className}>{link.name}</NavLink>
  }

  return (
    <nav>
      <div className="container nav-container">
        <Link to="/">
          <Logo className="nav-logo" />
        </Link>
        <ul className={'nav-links ' + (isOpen ? 'active' : '')}>
          {
            links.map(
              (link) => (
                <li key={link.path} className={link.style ?? ''}>
                  {getButton(link)}
                </li>
              )
            )
          }
        </ul>
        <button className='nav-toggle' onClick={() => setIsOpen(!isOpen)}>
          <IoMdMenu />
        </button>
      </div>
    </nav>
  )
}

export default Navbar