import {ReactComponent as Logo} from '../../images/logo-unirmentes-blanco.svg'
import './footer.css'
import WhatsappAction from '../WhatsappAction/WhatsappAction';
import { contactNumber1, contactEmail, pqrsEmail } from '../../data';

const Footer = () => {
    return (
        <div className="container footer-container">
            <div className='wa-icon-footer'>
                <WhatsappAction />
            </div>
            <div className='footer-row'>
                <Logo className='footer-logo' />
                <div className='contacto'>
                    <h5>Entra en contacto con nosotros</h5>
                    <ul>
                        <li>Horario</li>
                        <li>De Lunes a Viernes de 9am a 12pm y 2pm a 5pm</li>
                        <li>Teléfonos</li>
                        <li>{contactNumber1}</li>
                        <li>{contactEmail}</li>
                        <li>Gestión de PQRS: {pqrsEmail}</li>
                    </ul>
                </div>
            </div>
            <div className='footer-row copy'>
                <ul>
                    <li>
                        <a href="/docs/politica-de-privacidad.pdf" target="_blank" rel="noopener noreferrer">Política de privacidad</a>
                    </li>
                    <li>
                        <a href="/docs/politica-de-uso.pdf" target="_blank" rel="noopener noreferrer">Política de uso</a>
                    </li>
                </ul>
                <ul className='align-r'>
                    <li>
                        Todos los Derechos Reservados 2023
                    </li>
                    <li>
                        Digital Psychology Solutions SAS (DPSS SAS) © All Rigths Reserved 2023
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Footer