import "./home.css";
import "./carousel.css";

import Banner from "../../images/banner-landing-DIGITALmente-design-reduced.jpg";
import CartoonService from "../../images/CartoonService.png";

import MINTIC from "../../images/Logo_MINTIC.jpg";
import {ReactComponent as IBM} from "../../images/ibm.svg";

import ServiceCard from "../../components/ServiceCard/ServiceCard";
import ButtonDefault from "../../components/Common/BtnDefault/ButtonDefault";
import BlogCard from "../../components/BlogCard/BlogCard";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useEffect, useState } from "react";
import { scroller } from 'react-scroll';
import Article from "../../models/Article";

const Home = () => {
  const [articles, setArticles] = useState([]);

  const handleBannerButtonClick = () => {
    window.open('https://app.unirmentes.com', '_blank');
  };
  const handlePricesButtonClick = () => {
    window.open('/bienestar-net');
  };

  useEffect(() => {
    const hash = window.location.hash.slice(1);

    if (hash) {
      scroller.scrollTo(hash, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, []);

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    const articles = await Article.getArticles(1,3);

    if (articles) {
      setArticles(articles.data);
    }
  };

  const handleBlogClick = () => {
    window.location.href = '/blog';
  }

  return (
    <>
      <header className="home-header">
        <img src={Banner} alt="UnirMENTES banner" />
        <div>
          <h3 style={{ marginBottom: 10 }}>
            Porque creemos que la vida humana
            puede mejorar.
          </h3>
          <h5 style={{ marginBottom: 10 }}>
            Mejora tu bienestar a través de servicios de educación experta estratégica en:
          </h5>
          <h5>
            Psicología<br></br>
            Finanzas<br></br>
            Asesoría Legal*<br></br>
          </h5>
          <span style={{ fontSize: 11 }}>
            *Derecho de Familia, Comercial y Laboral - Únicamente para Colombia
          </span>
          <ButtonDefault onClick={handleBannerButtonClick}>Programa tu sesión online</ButtonDefault>
          <ButtonDefault onClick={handlePricesButtonClick}>Consulta nuestros paquetes</ButtonDefault>
          <ButtonDefault hideOnDesktop onClick={handlePricesButtonClick}>Regala bienestar</ButtonDefault>
        </div>
      </header>
      <section className="welcome">
        <div>
          <div className="box red-box">
            <h5>
              Les damos la bienvenida a UnirMENTES: Psicología y bienestar a un
              clic.
            </h5>
            <p>
              En UnirMENTES brindamos servicios digitales que promueven el
              desarrollo humano.
            </p>
          </div>
        </div>
        <div>
          <div className="box pink-box">
            <p>Nos interesa el desarrollo del bienestar humano, por eso trabajamos
            a diario para brindar una experiencia que resuelva las necesidades
            de personas, profesionales y empresas.
            </p>
          </div>
        </div>
      </section>
      <section className="who-we-are">
        <div className="why-us">
          <h4>¿Por qué elegirnos?</h4>
          <p>
            En un mismo sitio web y a sólo un clic encuentras servicios de base
            tecnológica que conectan,promueven, protegen y benefician a
            personas, profesionales y empresas interesadas en el fomento del
            bienestar, el desarrollo humano y la salud mental.
          </p>
          <hr />
        </div>
        <div id="services" className="services">
          <ServiceCard
            title="Bienestar NET"
            content="Espacio de encuentro, interacción y diálogo en donde se suministran servicios profesionales de diferentes áreas del conocimiento dirigidos al fomento del bienestar humano."
            addButton={true}
            actionLink={{ link: "https://app.unirmentes.com", label: "Acceder" }}
          >
            <h3 style={{ fontWeight: 'bold' }}>En Bienestar NET</h3>
            <br />
            <p style={{ fontWeight: 'bold' }}>Encuentra la educación profesional especializada y experta que necesitas:</p>
            <br />
            <ul>
              <li>
                <b>Wellness coach -</b> Valoramos tu necesidad y te brindamos conocimiento y estrategias dirigidas al fomento de tu bienestar personal, familiar o laboral.
              </li>
              <li>
                <b>Inteligencia financiera -</b> Valoramos tu necesidad y te brindamos conocimiento y estrategias dirigidas a fortalecer tu talento para el manejo, control y optimización de tus recursos económicos.
              </li>
              <li>
                <b>Health Coach -</b> Valoramos tu necesidad y te brindamos conocimiento y estrategias dirigidas a identificar tus principales dificultades y fortalecer las habilidades para lograr tus metas en autocuidado de tu salud.
              </li>
              <li>
                <b>Propósito de vida (Purpose  of life) -</b> Valoramos tu necesidad y te brindamos conocimiento y estrategias dirigidas a aclarar y fortalecer tus talentos,  habilidades y valores para la construcción de un sentido de propósito en tu vida.
              </li>
              <li>
                <b>Asesoría legal* -</b> Valoramos tu necesidad  y te brindamos conocimiento y estrategias para prevenir o manejar dificultades legales que puedan afectar tu bienestar. 
              </li>
            </ul>
            
            <br />
            <p style={{ fontWeight: 'bold' }}>La educación especializada y experta en Psicologia se encuentra dirigida a:</p>
            <ol>
              <li>Personas que deseen mejorar su bienestar o salud mental.</li>
              <li>Empresas que deseen mejorar el bienestar de sus trabajadores.</li>
              <li>Profesionales que necesitan apoyo profesional para el desarrollo de sus labores en el área de salud o bienestar.</li>
            </ol>
            <br />
            <p style={{ fontWeight: 'bold' }}>La educación especializada y experta en finanzas se encuentra dirigida a:</p>

            <ol>
              <li>Personas que deseen mejorar su calidad de vida a través de la inversión.</li>
              <li>Personas que deseen mejorar el manejo de sus finanzas personales.</li>
              <li>Empresas que deseen mejorar el bienestar de sus trabajadores a través de educación financiera.</li>
            </ol>

            <br />
            <p style={{ fontWeight: 'bold' }}>La asesoría legal especializada y experta*  se encuentra dirigida a:</p>
            <ol>
              <li>Personas que tienen dudas acerca de aspectos legales que pueden afectar su bienestar en el momento presente o futuro.</li>
              <li>Empresas que deseen mejorar el bienestar de sus trabajadores.</li>
            </ol>
            <br />
            <p style={{ fontSize: 11 }}> *Exclusiva para Colombia, no incluye tributaria, ni penal. La cita no incluye el costo de desarrollo documental.</p>
          </ServiceCard>
          <ServiceCard
            title="Soft M"
            content="Servicios de software a la medida, acorde a las necesidades de nuestro público, que permiten la optimización de nuestros servicios."
            actionLink={{ link: "https://www.d-p-s-s.com/", label: "Ver mas" }}
            addButton={true}
          >
            <p>
              Las soluciones de Software a la medida (SOFT-M) que proveemos se encuentran dirigidas a facilitar la integración y optimización de plataformas digitales en salud o bienestar que deseen trabajar con el sistema Psy-One v4 o con Bienestar NET. Dependiendo de las necesidades de las instituciones o grupos de profesionales se llevan a cabo desarrollos de software que optimizan el funcionamiento, integración de sistemas o la creación de nuevas aplicaciones que sean requeridas
            </p>
            <p>

            </p>
          </ServiceCard>
          <ServiceCard
            title="Psy-OneV4"
            content="Una solución web oportuna, adaptable a diferentes servicios de salud y de fácil manejo que provee la infraestructura tecnológica y documental para la operación de la Clínica digital en Psicologia"
            addButton={true}
          >
            <p>
              Te presentamos la Clínica digital en Psicología Psy-One V4 Para
              profesionales de la salud o el bienestar que desean ofrecer
              servicios que promueven el bienestar humano y la salud mental con
              estándares de calidad.
            </p>
            <br />
            <p>
              Psy-One V4 es una plataforma tecnológica, una solución web
              oportuna, adaptable a diferentes servicios de salud y de fácil
              manejo que provee la infraestructura tecnológica y documental para
              la operación de la Clínica digital en Psicología para servicios
              intramurales, extramurales y de Telesalud.
            </p>
            <br />
            <p>
              <b>Con PSY-ONEv4 puedes:</b>
            </p>
            <ul>
              <li>
                Operar tu clínica digital en Psicología y mejorar la seguridad
                de la información, la confidencialidad y el manejo de datos
                sensibles en el proceso de atención clínica.
              </li>
              <li>Desarrollar labores de Tele asistencia y Tele Educación</li>
              <li>
                Atender pacientes a nivel internacional. No olvides cumplir las
                condiciones legales de cada país.
              </li>
              <li>Realizar labores de tele-apoyo o teleorientación.</li>
              <li>
                Atender personas de otras culturas o en condición de
                discapacidad auditiva gracias a nuestro perfil intérprete.
              </li>
              <li>Contar con múltiples modalidades de atención psicológica.</li>
              <li>
                Contar con estadísticas actualizadas que te permiten mejorar la
                calidad del servicio y responder a las necesidades de tus
                pacientes
              </li>
              <li>Exportar RIPs a entidades de salud pertinentes.</li>
              <li>
                Cumplir con las condiciones de ley en la operación de tu
                servicio.
              </li>
              <li>Habilitar tu servicio de salud con mayor facilidad.</li>
              <li>
                Participar con otros profesionales en procesos de
                Teleasistencia.
              </li>
            </ul>
            <br />
            <p>
              Si deseas información o estás interesado(a) en este servicio
              comunícate por whatsapp
            </p>
          </ServiceCard>
          <img
            className="img-decoration"
            src={CartoonService}
            alt="Decoration services"
          />
        </div>
      </section>
      
      
      { articles && articles.length > 0 && (
        <section className="section-content blog">
          <h3>Blog</h3>
          <div className="three-in-row">
            {articles.map((article) => (
              <BlogCard
                key={article.id}
                img={article.image
                  ? `data:${article.image.mime_type};base64,${article.image.thumbnail_image_base64}`
                  : null}
                title={article.title}
                author={article.user.name}
                date={article.created_at}
                summary={article.summary}
                authorUrl={article.user.email}
                url={`/blog/${article.url_path}`}
                credits={article.image?.credits}
              />
            ))}
          </div>
          <ButtonDefault onClick={handleBlogClick}>Ver todos los artículos</ButtonDefault>
        </section>
      )}

      <section className="section-content contact" id="contact">
        <h3>Contáctanos</h3>
        <ContactForm />
      </section>
      <section className="section-content support-section">
        <h3>Con El Apoyo De</h3>
        <div className="support">
          <IBM alt="IBM" />
          <img src={MINTIC} alt="MINTIC" />
        </div>
      </section>
    </>
  );
};


/*
<section className="section-content reviews">
        <h3>Profesionales destacados</h3>
        <Carousel showArrows={false} showThumbs={false} showStatus={false}>
          {reviews.map((review) => (
            <div className="review-card" key={review.id}>
              <img src={review.img} alt={review.author} />
              <p>{review.content}</p>
            </div>
          ))}
        </Carousel>
      </section>
      */


export default Home;
