import './buttondefault.css'
import { useState, useEffect } from 'react'

const ButtonDefault = (props) => {
    const { hideOnDesktop } = props;
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 1024);
        };

        window.addEventListener('resize', handleResize);
        
        // Limpieza del event listener cuando el componente se desmonta
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isDesktop && hideOnDesktop) {
        return null;
    }

    return (
        <button className='button-default' onClick={props.onClick} type={props.type ?? "button"}>{props.children}</button>
    )
}

export default ButtonDefault